import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { Presentation } from "../models/presentation";
import { JoinPresentation } from "../models/join-presentation";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { MoveSlide } from "../models/move-slide";
import { ActionType } from "../models/actionType";
import { Message } from "../models/message";
import { PresentationStatus } from "../models/presentationStatus";
import styled from "styled-components";
import { UpdateSlide } from "../models/update-slide";
import ConnectionStatus from "../components/connection-status";
import NavButton from "../components/nav-button";
import { MadeBy } from "../components/made-by";
import * as Sentry from "@sentry/react";

const Page = styled.div`
  height: 100%;
  position: absolute;
  width: 100%;
  background-color: rgb(244, 244, 246);
`;

const Container = styled.div`
  align-items: center;
  height: 100%;
  justify-items: center;
  width: 100%;
  display: flex;
  flex-direction: column;
`;

const Header = styled.div`
  margin-top: 16px;
  margin-bottom: 40px;
  text-align: center;
`;

const Title = styled.h1`
  margin-top: 8px;
  font-size: 28px;
`;

const Slides = styled.h2`
  margin-top: 0;
  font-size: 18px;
`;

const Controls = styled.div`
  border: 2px solid rgba(64, 87, 109, 0.07);
  border-radius: 4px;
  max-height: 480px;
  max-width: 336px;
  padding: 16px;
  box-sizing: border-box;
  height: 100%;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: space-between;
  column-gap: 16px;
  margin-bottom: 48px;
`;

const MadeByHusky = styled(MadeBy)`
  position: fixed;
  bottom: 0;
  font-size: 12px;
`;

const Controller = () => {
  const { magicId } = useParams();
  const [presenterId] = useState<string>(uuidv4());
  const [presentation, setPresentation] = useState<Presentation>();
  const [currentSlide, setCurrentSlide] = useState<number>(0);
  const [status, setStatus] = useState(PresentationStatus.CONNECTING);
  const [error, setError] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);

  const [socketUrl] = useState<string>(
    `${process.env.REACT_APP_WEB_SOCKET_URL}?presenterId=${presenterId}&magicId=${magicId}`
  );

  useEffect(() => {
    if (presenterId && magicId) {
      Sentry.setContext("presentation", {
        presenterId,
        magicId,
      });
    }
  }, [presenterId, magicId]);

  const heartbeatRef = useRef<number | null>(null);

  useEffect(() => {
    if (error) {
      Sentry.captureMessage(error, "error");
    }
  }, [error]);

  const joinPresentation = (event: WebSocketEventMap["open"]) => {
    if (magicId) {
      let userName: string | null = null;

      if (!name) {
        userName = prompt("What's your name?");
        setName(userName);
      }

      const message: JoinPresentation = {
        action: ActionType.JOIN_PRESENTATION,
        magicId: magicId,
        presenterId: presenterId,
        name: userName ?? "User",
      };

      sendJsonMessage(message);

      console.log("WebSocket - onOpen");
      console.log(message);

      heartbeat();
    }
  };

  const messageReceived = (event: WebSocketEventMap["message"]) => {
    console.log(`WebSocket - Incoming Message: ${event.data}`);

    const message: Message = JSON.parse(event.data);
    if (message.action === ActionType.DETAILS_UPDATED) {
      const detailsMessage: Presentation = JSON.parse(event.data);

      if (detailsMessage && detailsMessage.magicId === magicId) {
        setPresentation(detailsMessage);
        setStatus(detailsMessage.status);
        setCurrentSlide(detailsMessage.currentSlide ?? 0);
      }
    } else if (message.action === ActionType.UPDATE_SLIDE) {
      const updateSlideMessage: UpdateSlide = JSON.parse(event.data);

      if (updateSlideMessage && updateSlideMessage.magicId === magicId) {
        setCurrentSlide(updateSlideMessage.newSlide);
      }
    }
  };

  const { sendJsonMessage, readyState } = useWebSocket(socketUrl, {
    share: true,
    shouldReconnect: (closeEvent: CloseEvent) => {
      setError(`WebSocket - Reconnecting: ${JSON.stringify(closeEvent)}`);
      console.log("WebSocket - Reconnecting...", closeEvent);

      return true;
    },
    reconnectAttempts: 5,
    reconnectInterval: 1000,
    onOpen: joinPresentation,
    onMessage: messageReceived,
    onClose: () => {
      console.log("WebSocket - Closed");
      if (heartbeatRef.current !== null) {
        window.clearTimeout(heartbeatRef.current);
      }

      setStatus(PresentationStatus.CLOSED);
    },
    onError: (event: Event) => {
      setError(`WebSocket generic error: ${JSON.stringify(event)}`);
      console.log("WebSocket generic error: ", event);
    },
  });

  const moveNext = () => {
    if (
      magicId &&
      presentation &&
      presentation.totalSlides &&
      currentSlide < presentation.totalSlides
    ) {
      const message: MoveSlide = {
        action: ActionType.MOVE_NEXT,
        magicId: magicId,
        presenterId: presenterId,
      };

      sendJsonMessage(message);
      setCurrentSlide((currentSlide) => currentSlide + 1);
    }
  };

  const movePrev = () => {
    if (magicId && currentSlide > 1) {
      const message: MoveSlide = {
        action: ActionType.MOVE_PREV,
        magicId: magicId,
        presenterId: presenterId,
      };

      sendJsonMessage(message);
      setCurrentSlide((currentSlide) => currentSlide - 1);
    }
  };

  const heartbeat = () => {
    if (
      readyState === ReadyState.CONNECTING ||
      readyState === ReadyState.OPEN
    ) {
      const heartbeatMessage = {
        action: ActionType.HEARTBEAT,
        presenterId: presenterId,
      };

      sendJsonMessage(heartbeatMessage);
      heartbeatRef.current = window.setTimeout(heartbeat, 10000); // 10 seconds ping
    }
  };

  return (
    <Page>
      <Container>
        <Header>
          {presentation && presentation.title && (
            <Title>{presentation.title}</Title>
          )}

          {presentation && presentation.totalSlides && (
            <Slides>
              Slide {currentSlide} of {presentation.totalSlides}
            </Slides>
          )}
        </Header>

        <ConnectionStatus status={status} />

        <Controls>
          <NavButton
            disabled={
              status !== PresentationStatus.ONLINE || currentSlide === 1
            }
            onClick={movePrev}
          >
            PREV
          </NavButton>
          <NavButton
            disabled={
              status !== PresentationStatus.ONLINE ||
              currentSlide === presentation?.totalSlides
            }
            onClick={moveNext}
          >
            NEXT
          </NavButton>
        </Controls>
        <MadeByHusky />
      </Container>
    </Page>
  );
};

export default Controller;
